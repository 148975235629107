import React from 'react'
import { NavLink } from 'react-router-dom'

import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

import RouteScrollTop from '../comps/assets/RouteScrollTop'

const styles = theme => ({
  headerContainer: {
    position: 'relative',
    top: theme.spacing(-12),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(-7),
    },
    '& img': {
      width: '100%',
      minHeight: 384,
      maxHeight: 512,
      objectFit: 'cover',
    },
  },
  pageSection: {
    padding: theme.spacing(10, 4),
    '&:first-of-type': {
      paddingTop: theme.spacing(0),
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(-12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 2),
      '&:first-of-type': {
        paddingTop: theme.spacing(3),
      },
      '&:last-of-type': {
        marginBottom: theme.spacing(-8),
      },
    },
  },
  primDesc: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  secDesc: {
    backgroundColor: theme.palette.secondary.main,
  },
  avatar: {
    // width: 48,
    // height: 48,
  },
})

class Page extends React.Component {
  render() {
    const { classes, despre } = this.props

    const comitetOrganizare = despre.organizare
    const comitetStiintific = despre.stiintific

    return (
      <React.Fragment>
        <div className={classes.headerContainer}>
          <img src="https://cnces.educatiepentrustiinta.ro/images/page-headers/page-header-00.jpg" alt="" />
        </div>
        <Container maxWidth="xl">
          <RouteScrollTop />
          <Grid container spacing={3} className={classes.pageSection}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h2" align="center">
                {this.props.pageName}
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={2} />
            </Hidden>
            <Grid item xs={12} md={8}>
              <Typography align="center" gutterBottom>
                <strong>Comunitatea Educație pentru Știință</strong> reprezintă un grup de inițiativă care are
                obiectivul de a crea o un mediu profesional integrator în domeniul educației pentru științe, prin
                dezvoltarea unei platforme de dialog și acțiune.
              </Typography>
              <Typography align="center" gutterBottom>
                Această platformă va susține schimbul de experiență și expertiză cu scopul de a facilita învățarea
                reciprocă pentru toți actorii implicați, în armonie cu tendințele și răspunsurile recente, noile
                provocări și orizonturi.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={clsx(classes.pageSection, classes.secDesc)}>
            <Hidden smDown>
              <Grid item xs={false} md={2} />
            </Hidden>
            <Grid item xs={12} md={8}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Comitet de organizare
              </Typography>
              <List className={classes.root} dense>
                {comitetOrganizare &&
                  comitetOrganizare.map((person, i) => {
                    return (
                      <React.Fragment>
                        {i > 0 && <Divider variant={person.avatar ? 'inset' : 'fullWidth'} component="li" />}
                        <ListItem alignItems="flex-start">
                          {person.avatar && (
                            <ListItemAvatar>
                              <Avatar alt={person.name} src={person.avatar} className={classes.avatar} />
                            </ListItemAvatar>
                          )}
                          <ListItemText
                            primary={
                              person.name && (
                                <Typography
                                  variant="body1"
                                  className={classes.inline}
                                  color="textPrimary"
                                  style={{ fontWeight: 500 }}>
                                  {person.name}
                                </Typography>
                              )
                            }
                            secondary={
                              <React.Fragment>
                                {person.fac && (
                                  <Typography variant="body2" className={classes.inline} color="textPrimary">
                                    {person.fac}
                                  </Typography>
                                )}
                                {person.uni && (
                                  <Typography variant="body2" className={classes.inline} color="textSecondary">
                                    {person.uni}
                                  </Typography>
                                )}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      </React.Fragment>
                    )
                  })}
              </List>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.pageSection}>
            <Hidden smDown>
              <Grid item xs={false} md={2} />
            </Hidden>
            <Grid item xs={12} md={8}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Comitet Științific
              </Typography>
              <List className={classes.root} dense>
                {comitetStiintific &&
                  comitetStiintific.map((section, i) => {
                    return (
                      <React.Fragment>
                        <Typography component="h3" variant="h5" align="center" gutterBottom>
                          {section.title}
                        </Typography>
                        {section.people.map((person, i) => {
                          return (
                            <React.Fragment>
                              {i > 0 && <Divider variant={person.avatar ? 'inset' : 'fullWidth'} component="li" />}
                              <ListItem alignItems="flex-start">
                                {person.avatar && (
                                  <ListItemAvatar>
                                    <Avatar alt={person.name} src={person.avatar} className={classes.avatar} />
                                  </ListItemAvatar>
                                )}
                                <ListItemText
                                  primary={
                                    person.name && (
                                      <Typography
                                        variant="body1"
                                        className={classes.inline}
                                        color="textPrimary"
                                        style={{ fontWeight: 500 }}>
                                        {person.name}
                                      </Typography>
                                    )
                                  }
                                  secondary={
                                    <React.Fragment>
                                      {person.fac && (
                                        <Typography variant="body2" className={classes.inline} color="textPrimary">
                                          {person.fac}
                                        </Typography>
                                      )}
                                      {person.uni && (
                                        <Typography variant="body2" className={classes.inline} color="textSecondary">
                                          {person.uni}
                                        </Typography>
                                      )}
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                            </React.Fragment>
                          )
                        })}{' '}
                      </React.Fragment>
                    )
                  })}
              </List>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={clsx(classes.pageSection, classes.secDesc)}>
            <Hidden smDown>
              <Grid item xs={false} md={2} />
            </Hidden>
            <Grid item xs={12} md={8}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Ediții anterioare
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography component="h3" variant="h5" align="center" gutterBottom>
                    CNCES 2017
                  </Typography>
                  {/* <Typography component="div" align="center" gutterBottom>
                    text
                  </Typography> */}
                  <Typography align="center">
                    <Button color="primary" variant="outlined" href="https://cnces2017.unibuc.ro" target="_blank">
                      Site-ul CNCES 2017
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography component="h3" variant="h5" align="center" gutterBottom>
                    CNCES 2015
                  </Typography>
                  {/* <Typography component="div" align="center" gutterBottom>
                    text
                  </Typography> */}
                  <Typography align="center">
                    <Button
                      color="primary"
                      variant="outlined"
                      href="https://cnces2017.unibuc.ro/despre-noi/cnces-2015/"
                      target="_blank">
                      Site-ul CNCES 2015
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Page)
