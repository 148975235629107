import React from 'react'
import { NavLink } from 'react-router-dom'

import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import RouteScrollTop from '../comps/assets/RouteScrollTop'

const styles = theme => ({
  headerContainer: {
    position: 'relative',
    top: theme.spacing(-12),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(-7),
    },
    '& img': {
      width: '100%',
      minHeight: 384,
      maxHeight: 512,
      objectFit: 'cover',
    },
  },
  pageSection: {
    padding: theme.spacing(10, 4),
    '&:first-of-type': {
      paddingTop: theme.spacing(0),
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(-12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 2),
      '&:first-of-type': {
        paddingTop: theme.spacing(3),
      },
      '&:last-of-type': {
        marginBottom: theme.spacing(-8),
      },
    },
  },
  primDesc: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  secDesc: {
    backgroundColor: theme.palette.secondary.main,
  },
})

class Page extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <div className={classes.headerContainer}>
          <img src="https://cnces.educatiepentrustiinta.ro/images/page-headers/page-header-02.jpg" alt="" />
        </div>
        <Container maxWidth="xl">
          <RouteScrollTop />
          <Grid container spacing={3} className={classes.pageSection}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h2" align="center">
                {this.props.pageName}
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={2} />
            </Hidden>
            <Grid item xs={12} md={8}>
              <p>
                Conferinţa se adresează profesorilor de ştiinţe din învăţământul preuniversitar (matematică, fizică,
                chimie, biologie, geografie, informatică, tehnologii, profesori învăţământ primar etc.), profesorilor
                consilieri școlari, studenților care se pregătesc pentru cariera didactică, universitarilor,
                cercetătorilor în educaţie, cercetătorilor ştiinţifici, membrilor unor organizaţii ce desfăşoară
                programe educaţionale, decidenţilor şi în general tuturor celor interesaţi de dezvoltarea unui
                învăţământ de calitate în domeniul STEM.
              </p>
              <ol>
                <li>
                  Profesori (din categoriile descrise la punctele 2 și 3) care provin din unități de învățământ
                  considerate vulnerabile conform următoarelor criterii:
                  <ul>
                    <li>
                      Unităţile de învăţământ liceal eligibile sunt unităţile considerate eligibile în proiectul ROSE
                      derulat de Guvernul României cu Banca Mondială.{' '}
                      <Button
                        variant="text"
                        color="primary"
                        href="https://cnces.educatiepentrustiinta.ro/resurse/ROSE_Lista-licee-eligibile_actualizata.pdf"
                        target="_blank">
                        vezi lista aici
                      </Button>
                    </li>
                    <li>
                      Unităţile de învăţământ primar şi gimnazial eligibile sunt considerate cele din mediul rural
                      situate la o distanţă mai mare de 30 de km de un centru universitar.
                    </li>
                  </ul>
                </li>
                <li>
                  Profesori din învățământul preuniversitar care predau discipline STEM: profesori învățământ primar,
                  secundar gimnazial și liceal.
                </li>
                <li>
                  Profesori consilieri școlari din rețeaua de cabinete școlare și interșcolare de asistență
                  psihopedagogică CMBRAE - CJRAE
                </li>
                <li>Alte categorii de participanți conform enumerării din primul paragraf.</li>
              </ol>
              <p>
                Numărul total de participanţi aşteptat este de aproximativ 150 - 200, fiecare grup ţintă având alocată o
                cotă.
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={clsx(classes.pageSection, classes.secDesc)}>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Sprijin financiar
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={2} />
            </Hidden>
            <Grid item xs={12} md={8}>
              <Typography component="div" align="left">
                <p>
                  <strong>Burse parţiale</strong> (acoperă taxa de conferinţă, mesele din timpul conferinţei, materiale
                  educaționale):
                </p>
                <ul>
                  <li>
                    cadre didactice din învăţământul preuniversitar şi celelalte categorii de participanţi în limita
                    fondurilor disponibile;
                  </li>
                </ul>
                <p>
                  <strong>Burse integrale</strong> (acoperă taxa conferinţei, mesele din timpul conferinţei, materiale
                  educaționale, cheltuielile de transport şi/ sau cazare):
                </p>
                <ul>
                  <li>
                    cadre didactice din unităţile vulnerabile care optează pentru participarea la cel puţin două zile
                    ale conferinţei (6, 7 sau 8 decembrie) și la cel puţin 6 dintre activităţile conferinţei;
                  </li>
                  <li>
                    cadre didactice din învăţământul preuniversitar (în limita fondurilor disponibile) care optează
                    pentru participarea în cel puţin două zile ale conferinţei (6, 7 sau 8 decembrie) și la cel puţin 6
                    dintre activităţile conferinţei.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant="contained" color="primary" size="large" component={NavLink} to="/ro/inscriere">
                Înscrie-te!
              </Button>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Page)
