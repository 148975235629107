import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  siteLogoMainMenu: {
    maxHeight: theme.spacing(6),
  },
  siteLogoFooter: {
    maxWidth: theme.spacing(32),
  },
}))

export default function CircularStatic(props) {
  const classes = useStyles()
  let LogoURL = props.siteLogo ? props.siteLogo : ''

  let LogoContainerIMG, LogoContainerCSS

  switch (props.siteLogoPosition) {
    case 'MainMenu':
      LogoContainerCSS = classes.siteLogoMainMenu
      break
    case 'Footer':
      LogoContainerCSS = classes.siteLogoFooter
      break
    default:
      LogoContainerCSS = classes.siteLogoMainMenu
  }

  switch (props.siteLogoType) {
    case 'IMG':
      LogoContainerIMG = <img src={LogoURL} alt={props.siteName} className={LogoContainerCSS} />
      break
    case 'SVG':
      LogoContainerIMG = <img src={LogoURL} alt={props.siteName} className={LogoContainerCSS} />
      break
    case 'SVGi':
      LogoContainerIMG = 'SVGi'
      break
    default:
      LogoContainerIMG = <img src={LogoURL} alt={props.siteName} className={LogoContainerCSS} />
  }

  return <React.Fragment>{LogoContainerIMG}</React.Fragment>
}
