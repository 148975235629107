import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { red } from '@material-ui/core/colors'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MapMarker from '@material-ui/icons/Room'

import ActivitiesDialog from './ActivitiesDialog'

const useStyles = makeStyles(theme => ({
  card: {
    // maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  activityPrim: {
    backgroundColor: 'rgb(182, 215, 168)',
  },
  activitySTEM: {
    backgroundColor: 'rgb(241,241,241)',
  },
  activityCons: {
    backgroundColor: 'rgb(159, 197, 232)',
  },
  activityPres: {
    backgroundColor: 'rgb(230, 184, 175)',
  },
}))

export default function RecipeReviewCard(props) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  // console.log(props)

  return (
    <Card className={clsx(classes.card, classes[props.activityType])} elevation={4}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {props.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.trainer}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          aria-label="View on Google Maps"
          title={props.location}
          href={`https://goo.gl/maps/${[props.maps]}`}
          target="_blank">
          <MapMarker />
        </IconButton>
        {/* <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        {/* <Button color="primary" className={classes.expand}>
          {props.dialog ? props.dialog.buttonText : 'Detalii'}
        </Button> */}
        {props.dialog && <ActivitiesDialog data={props.dialog} />}
      </CardActions>
    </Card>
  )
}
