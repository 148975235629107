import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import ReactGA from 'react-ga'

import { theme } from './themes/MainTheme'
import './styles.css'

import { useFetch } from './hooks/fetchJSON'
import componentList from './core/ComponentIterator'

import ProgressBar from './comps/functional/ProgressBar'
import MainMenu from './comps/MainMenu'
import Footer from './layouts/page/Footer'

import ShowBreakpoints from './tests/ShowBreakpoints'

import WebFont from 'webfontloader'
WebFont.load({
  google: {
    families: [
      'Bree+Serif:400:latin,latin-ext',
      'Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i:latin,latin-ext',
      'sans-serif',
    ],
  },
})

function initializeReactGA() {
  ReactGA.initialize('UA-153167348-2')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

// https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
// https://codesandbox.io/s/use-scroll-position-8nfin

const useStyles = makeStyles({
  'html>body': {
    backgroundColor: theme.palette.primary.main,
  },
  main: {
    minHeight: '100vh',
    padding: theme.spacing(12, 0),
    backgroundColor: 'white',
    background: `linear-gradient(0deg, rgba(110, 109, 91, 1) 0, rgba(${
      theme.palette.primary.main
      }, 0) 256px) no-repeat`,
    color: 'initial',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(7, 0),
    },
  },
})

export default withWidth()(function App(props) {
  const classes = useStyles()

  const [siteData, setSiteData] = React.useState([])

  const config = props.config
  const { siteName, siteDefaultLanguage } = config
  const siteLanguage = siteDefaultLanguage
  const { loading, data } = useFetch('mainRequest')

  React.useEffect(() => {
    setSiteData(data)
  }, [data])

  if (loading) {
    return (
      <Container maxWidth="sm" style={{ paddingTop: isWidthDown('sm', props.width) ? 96 : 128, textAlign: 'center' }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
          {siteName}
        </Typography>
        <br />
        <ProgressBar size={48} thickness={3} />
        <br />
        <Typography>SE ÎNCARCĂ</Typography>
      </Container>
    )
  } else {
    const routeData = siteData[0].routes
    const sliderData = siteData[1].homePage
    const programData = siteData[2].program
    const despreData = siteData[3].comitete

    initializeReactGA()

    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* <Breadcrumbs breadcrumbNameMap={breadcrumbNameMap} /> */}
          <MainMenu position="top" siteStructure={routeData} {...config} />
          <main className={classes.main}>
            <ShowBreakpoints theme={theme} show={false} />
            <Switch>
              <Route path="/" exact render={() => <Redirect to={'/' + siteLanguage + '/'} />} />
              {routeData.map((route, index) => {
                const ComponentIterator = componentList[route.component]
                const routePath = '/' + siteLanguage + '/' + route.path
                if (route.route)
                  return (
                    <Route {...route.routeProps} path={routePath} key={index}>
                      <ComponentIterator
                        {...route.props}
                        {...config}
                        siteLanguage={siteLanguage}
                        pageName={route.name}
                        setData={sliderData}
                        program={programData}
                        despre={despreData}
                      />
                    </Route>
                  )
              })}
            </Switch>
          </main>
          <Footer {...config} />
        </ThemeProvider>
      </BrowserRouter>
    )
  }
})
