import React from 'react'
import PropTypes from 'prop-types'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import withWidth from '@material-ui/core/withWidth'

class ShowBreakpoints extends React.Component {
  render() {
    const { theme, width, show } = this.props

    if (show) {
      return (
        <Container maxWidth="xl">
          <Paper
            style={{
              margin: theme.spacing(4, 0, 11),
              padding: theme.spacing(2),
              backgroundColor: 'teal',
              color: 'white',
              textAlign: 'center',
            }}>
            breakpoint: <strong style={{ textTransform: 'uppercase' }}>{width}</strong>
          </Paper>
        </Container>
      )
    } else {
      return null
    }
  }
}

ShowBreakpoints.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
}

export default withWidth()(ShowBreakpoints)
