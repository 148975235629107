import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay, bindKeyboard } from 'react-swipeable-views-utils'

// import clsx from 'clsx'
import { withStyles, withTheme } from '@material-ui/core/styles'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'

import Paper from '@material-ui/core/Paper'
// import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// import Fab from '@material-ui/core/Fab'
// import BackIcon from '@material-ui/icons/ArrowBack'
// import Icon from '@mdi/react'
// import { mdiPause, mdiPlay } from '@mdi/js'

import Pagination from './Pagination'
// import ProgressBar from "./ProgressBar";

const AutoPlaySwipeableViews = bindKeyboard(autoPlay(SwipeableViews))

const styles = theme => ({
  root: {
    position: 'relative',
    top: theme.spacing(-12),
    // width: '100vw',
    // margin: 0,
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(-7),
    },
  },
  slideContainer: {
    // borderRadius: theme.shape.borderRadius + 1,
    // height: '100vh',
  },
  slide: {
    position: 'relative',
    display: 'flex',
    // height: '100vh',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: 'grey',
  },
  image: {
    width: '100vw',
    objectFit: 'cover',
  },
  imageTitle: {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    width: '100vw',
    boxSizing: 'border-box',
    background: 'linear-gradient(0deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 100%)',
    color: 'white',
    fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    // fontSize: '1.16rem',
    lineHeight: '2.5em',
    textAlign: 'left',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  autoPlay: {
    position: 'absolute',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  progressPosition: {
    position: 'absolute',
  },
  progressCircle: {
    stroke: 'rgba(255,255,255,.75)',
    fill: 'transparent',
  },
  backButton: {
    position: 'absolute',
    top: 16,
    left: 16,
    padding: 0,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.7)',
    },
  },
  backButtonIcon: {
    // color: theme.palette.secondary.main
    color: 'white',
  },
})

export default withWidth()(
  withTheme(
    withStyles(styles)(
      class ProductGallery extends React.Component {
        state = {
          index: 0,
          autoplay: this.props.autoplay,
        }

        handleChangeIndex = index => {
          this.setState({
            index,
          })
        }

        handleAutoPlay = () => {
          this.setState({
            autoplay: !this.state.autoplay,
          })
        }

        handleGoBack = () => {
          this.props.history.push('/' + this.props.siteLanguage + '/products')
        }

        returnMainImage(src, index, name) {
          const { classes } = this.props
          return <img src={src} className={classes.image} alt={name} />
        }

        render() {
          const { index, autoplay } = this.state
          const {
            theme,
            classes,
            images,
            interval,
            dotSize,
            fabSize,
            fabPosition,
            thumbGridSpacing,
            thumbRowLenght,
          } = this.props

          const spacing = 2

          // default progress size
          let progressSize = 48,
            progressBarThickness = 4
          switch (fabSize) {
            case 'small':
              progressSize = 40
              progressBarThickness = 3
              break
            case 'medium':
              progressSize = 48
              break
            case 'large':
              progressSize = 56
              break
            default:
              progressSize = 56
              progressBarThickness = 4
          }
          const progressBarRadius = (progressSize - progressBarThickness) / 2
          // adjust imageTitle accordingly
          const imageStyles = {
            // width: '100vw',
            height: '100vh',
          }
          const imageTitleStyles = {
            // lineHeight: `${progressSize + theme.spacing(1)}px`,
            // padding: isWidthDown('sm', this.props.width)
            //   ? theme.spacing(1.5, (fabPosition + progressSize) / 8, 2.5, fabPosition / 8)
            //   : theme.spacing(1.5, (fabPosition + progressSize) / 8, 2.5, fabPosition / 4),
            padding: theme.spacing(1.5, 4),
            paddingRight: isWidthDown('sm', this.props.width)
              ? theme.spacing(4)
              : images.length * dotSize + images.length * spacing * 8 + theme.spacing(6),
          }

          return (
            <div className={classes.root}>
              <AutoPlaySwipeableViews
                autoplay={autoplay}
                interval={interval}
                index={index}
                onChangeIndex={this.handleChangeIndex}
                className={classes.slideContainer}
                springConfig={{
                  duration: '0.5s',
                  easeFunction: 'ease-in-out',
                  delay: '0s',
                }}>
                {images.map((image, index) => {
                  return (
                    <Paper key={index} className={classes.slide} style={imageStyles} square>
                      {/* <Fab
                      color="secondary"
                      size="large"
                      onClick={this.handleGoBack}
                      className={clsx(classes.backButton)}>
                      <BackIcon className={classes.backButtonIcon} />
                    </Fab> */}
                      <Typography component="div" variant="h4" className={classes.imageTitle} style={imageTitleStyles}>
                        {image.name}
                      </Typography>
                      {this.returnMainImage(image.src, index, image.name)}
                    </Paper>
                  )
                })}
              </AutoPlaySwipeableViews>
              {/* {autoplay && (
              <svg
                height={progressSize}
                width={progressSize}
                className="circularProgressBar"
                style={{
                  width: progressSize,
                  height: progressSize,
                  right: fabPosition,
                  bottom: fabPosition,
                  strokeDasharray: Math.PI * 2 * progressBarRadius,
                  strokeDashoffset: Math.PI * 2 * progressBarRadius,
                  animationDuration: `${interval / 1000}s`,
                }}>
                <circle
                  cx={progressSize / 2}
                  cy={progressSize / 2}
                  r={progressBarRadius}
                  strokeWidth={progressBarThickness}
                  className={classes.progressCircle}
                />
              </svg>
            )} */}
              {/* {images.length > 1 && (
              <Fab
                size={fabSize}
                onClick={this.handleAutoPlay}
                className={classes.autoPlay}
                style={{
                  right: fabPosition,
                  bottom: fabPosition,
                }}>
                <Icon path={autoplay ? mdiPause : mdiPlay} size={1} color="white" />
              </Fab>
            )} */}
              <Pagination
                index={index}
                onChangeIndex={this.handleChangeIndex}
                autoplay={this.state.autoplay}
                handleAutoPlay={this.handleAutoPlay}
                images={images}
                rowLenght={thumbRowLenght}
                gridSpacing={thumbGridSpacing}
                spacing={spacing}
                dotSize={dotSize}
              />
            </div>
          )
        }
      },
    ),
  ),
)
