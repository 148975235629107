import React from 'react'
import { NavLink } from 'react-router-dom'

import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
import { ThemeProvider, makeStyles, createMuiTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Icon from '@mdi/react'
import { mdiMail, mdiPhone, mdiMap } from '@mdi/js'

import ContactForm from '../../comps/forms/ContactForm'
import SocialIcons from '../../comps/SocialIcons'

import { theme } from '../../themes/MainTheme'

import SiteLogo from '../../comps/assets/SiteLogo'

const darkTheme = createMuiTheme({
  ...theme,
  palette: {
    type: 'dark',
    primary: { main: theme.palette.primary.main },
    secondary: { main: theme.palette.secondary.main },
  },
})

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(12, 8, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10, 8, 0),
    },
    backgroundColor: '#181818',
    color: 'white',
  },
  footerLeft: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
      textAlign: 'center',
    },
  },
  footerRight: {},
  siteLogo: {
    width: theme.spacing(20),
    marginRight: theme.spacing(2),
    verticalAlign: 'top',
    fill: theme.palette.common.white,
  },
  siteLogoName: {
    // display: 'inline-block',
    // lineHeight: `${theme.spacing(12)}px`,
  },
  contactText: {
    paddingLeft: theme.spacing(2),
    lineHeight: `${theme.spacing(3)}px`,
    fontWeight: 300,
    // [theme.breakpoints.down('xs')]: {
    //   padding: theme.spacing(0.75, 2),
    // },
  },
  contactIcon: {
    // width: `${theme.spacing(3)}px !important`,
    // height: `${theme.spacing(3)}px !important`,
    // marginLeft: theme.spacing(-4),
    // marginRight: theme.spacing(3),
    verticalAlign: 'middle',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  copyright: {
    margin: theme.spacing(12, -8, 0),
    padding: theme.spacing(10, 0, 8),
    backgroundColor: '#121212',
    color: theme.palette.grey[400],
    textAlign: 'center',
  },
  designedBy: {
    '&, &>a': {
      fontWeight: 400,
      color: theme.palette.grey[800],
      textTransform: 'none',
    },
  },
}))

export default withWidth()(function Footer(props) {
  const classes = useStyles()
  const { siteName, siteLogo, siteLogoType, showSiteName, siteLanguage } = props
  const currentYear = new Date().getFullYear()

  function createMarkup(content) {
    return { __html: content }
  }

  return (
    <footer className={classes.root}>
      <ThemeProvider theme={darkTheme}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={5} className={classes.footerLeft}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SiteLogo
                    siteLogo={siteLogo}
                    siteLogoType={siteLogoType}
                    siteName={siteName}
                    siteLogoPosition="Footer"
                  />
                  {JSON.parse(props.showSiteName) && (
                    <Typography variant="h6" className={classes.siteLogoName}>
                      {props.siteName}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Grid container spacing={2}>
                    {props.siteContactEmail && (
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          startIcon={<Icon path={mdiMail} size={1} color="white" className={classes.contactIcon} />}
                          className={classes.contactText}
                          // href={`tel:${props.siteContactPhone}`}
                          target="_blank"
                          style={{ textTransform: 'none', cursor: 'default' }}>
                          {props.siteContactEmail}
                        </Button>
                      </Grid>
                    )}
                    {props.siteContactPhone && (
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          startIcon={<Icon path={mdiPhone} size={1} color="white" className={classes.contactIcon} />}
                          className={classes.contactText}
                          href={`tel:${props.siteContactPhone}`}
                          target="_blank"
                          style={{ textTransform: 'none' }}>
                          {props.siteContactPhone}
                        </Button>
                      </Grid>
                    )}
                    {props.siteContactAddress && (
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          startIcon={<Icon path={mdiMap} size={1} color="white" className={classes.contactIcon} />}
                          className={classes.contactText}
                          href={props.siteContactMapLink}
                          target="_blank"
                          style={{ textTransform: 'none' }}>
                          <span dangerouslySetInnerHTML={createMarkup(props.siteContactAddress)} />
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={2} />
            </Hidden>
            <Grid item xs={12} md={5} className={classes.footerRight}>
              <ContactForm />
            </Grid>
          </Grid>
        </Container>
        <div className={classes.copyright}>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SocialIcons
                  size=""
                  showIcons={[
                    {
                      name: 'Facebook',
                      url: 'https://www.facebook.com/scientixRo',
                    },
                    // {
                    //   name: 'Instagram',
                    //   url: 'https://www.instagram.com/',
                    // },
                    // { name: 'Pinterest', url: 'https://pinterest.com' },
                    // { name: 'Twitter', url: 'https://twitter.com' },
                    // { name: 'Behance', url: 'https://behance.net' },
                    // { name: 'Dribbble', url: 'https://dribbble.com' },
                  ]}
                  // square
                />
                <Typography variant="caption" component="div">
                  Copyright &copy; {currentYear}{' '}
                  <Button
                    size="small"
                    color="inherit"
                    variant="text"
                    href={props.siteCopyrightOwnerURL}
                    target="_blank">
                    {props.siteCopyrightOwner}
                  </Button>
                  <br />
                  Toate drepturile rezervate.
                </Typography>
                {/* <Button
                  size="small"
                  color="inherit"
                  variant="text"
                  to="/ro/politica-de-confidentialitate"
                  component={NavLink}>
                  Politica de confidențialitate
                </Button> */}
              </Grid>
            </Grid>
            <br />
            <Typography variant="caption" component="div" className={classes.designedBy}>
              designed and developed by{' '}
              <Button size="small" href="https://edgencia.ro" target="_blank">
                edgencia
              </Button>
            </Typography>
          </Container>
        </div>
      </ThemeProvider>
    </footer>
  )
})
