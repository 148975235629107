import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  currentPanel: {
    backgroundColor: theme.palette.secondary.light,
  },
  headingContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'calc(100% - 64px)',
  },
  heading: {
    width: '100%',
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexGrow: 1,
    flexShrink: 0,
    color: theme.palette.text.primary,
    fontWeight: 500,
    textAlign: 'left',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}))

export default function ControlledExpansionPanels() {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        classes={{ expanded: classes.currentPanel }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          classes={{ content: classes.headingContainer }}>
          <Typography className={classes.heading} component="div">
            Pot participa prin plata taxei de conferinţă?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Participarea cadrelor didactice și a consilierilor din învăţământul preuniversitar la conferința este
            susţinută de către organizatori, pentru alte categorii de participanţi este posibilă participarea prin plata
            unei taxe menţionată în regulamentul de înscriere.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
        classes={{ expanded: classes.currentPanel }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          classes={{ content: classes.headingContainer }}>
          <Typography className={classes.heading} component="div">
            Care sunt categoriile care pot beneficia de bursă parţială sau integrală de participare?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Toţi cei interesaţi de dezvoltarea unui învăţământ de calitate în domeniul STEM în România pot beneficia de
            burse parţiale de participare în limita unor cote stabilite pentru fiecare categorie de participanţi.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
        classes={{ expanded: classes.currentPanel }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          classes={{ content: classes.headingContainer }}>
          <Typography className={classes.heading} component="div">
            Pot să bifez în același timp opțiunile de participare cu bursă integrală sau cu o bursă parțială?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Da, în cadrul selecției cererea va fi luată în considerare la ambele categorii. Invitarea dumneavoastră la
            conferința se va face după confirmarea participării în categoria la care ați fost selectat.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
        classes={{ expanded: classes.currentPanel }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
          classes={{ content: classes.headingContainer }}>
          <Typography className={classes.heading} component="div">
            Pot să aleg să particip la o singură zi de conferinţă?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Da, însă, în acest caz, nu puteţi fi beneficiarul unei burse integrale.</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
        classes={{ expanded: classes.currentPanel }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
          classes={{ content: classes.headingContainer }}>
          <Typography className={classes.heading} component="div">
            Pot să aleg să particip doar pe data 6 şi de 8 decembrie?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Da, însă, în acest caz, nu puteţi fi beneficiarul unei burse integrale.</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
        classes={{ expanded: classes.currentPanel }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
          classes={{ content: classes.headingContainer }}>
          <Typography className={classes.heading} component="div">
            Pentru participarea la conferinţă se acordă diplome de participare?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Da, participarea la conferinţă este recunoscută prin diplome de participare.</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}
