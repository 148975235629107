import { createMuiTheme } from '@material-ui/core/styles'
import { red, amber, grey } from '@material-ui/core/colors'
// import { dark } from '@material-ui/core/styles/createPalette'

const mainColor = 'white'
const theme = createMuiTheme({
  palette: {
    // type: 'dark',
    primary: { main: '#673ab7' },
    secondary: { main: amber[500] },
  },
  overrides: {
    MuiButton: {
      root: {
        color: mainColor,
      },
    },
    MuiAppBar: { colorPrimary: { color: mainColor } },
    MuiDrawer: {
      paper: {
        backgroundColor: grey[800],
      },
    },
    MuiListItem: {
      root: {
        // backgroundColor: grey[800],
        // color: grey[50],
        // fontWeight: 700,
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: 700,
      },
    },
  },
})

export { theme }
