export default {
  siteNameFull: 'Conferinţa Naţională a Comunităţii "Educaţie pentru Ştiinţe", Ediţia a III-a, 6-8 Decembrie 2019',
  siteName: 'Conferinţa Naţională a CES',
  siteNameCaption: 'Ediţia a III-a, 6-8 Decembrie 2019',
  siteNameSlogan: 'Comunități de învățare, comunități de bune practici, ecosisteme dinamice educaționale',
  siteLogo: 'https://educatiepentrustiinta.ro/images/cest-logo-white.128.png',
  siteLogoType: 'IMG', // SVG, SVGi
  showLogo: true,
  showSiteName: true,
  siteDefaultLanguage: 'ro',
  siteCopyrightOwner: 'Comunitatea "Educaţie pentru Ştiinţe"',
  siteCopyrightOwnerURL: 'https://educatiepentrustiinta.ro',
  // contact
  siteContactEmail: 'conferinta@educatiepentrustiinta.ro',
  // siteContactPhone: '+40 000 00 0000',
  siteContactPhone: '',
  siteContactAddress: 'Platforma de educație și cercetare Măgurele, Ilfov, România',
  siteContactMapLink: 'https://goo.gl/maps/9BSAgTNy2h6C1xCv9',
}
