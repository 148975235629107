import React from 'react' // useRef
import { NavLink } from 'react-router-dom'

import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import RouteScrollTop from '../comps/assets/RouteScrollTop'
import Slider from '../comps/SwipeableSlider/SwipeableSlider'

const styles = theme => ({
  pageSection: {
    padding: theme.spacing(10, 4),
    '&:first-of-type': {
      paddingTop: theme.spacing(0),
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(-12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 2),
      '&:first-of-type': {
        paddingTop: theme.spacing(3),
      },
      '&:last-of-type': {
        marginBottom: theme.spacing(-8),
      },
    },
  },
  primDesc: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  secDesc: {
    backgroundColor: theme.palette.secondary.main,
  },
})

class Home extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <RouteScrollTop />
        <Slider
          siteLanguage={this.props.siteLanguage}
          images={this.props.setData}
          autoplay={true}
          interval={4500}
          dotSize={64}
          fabSize="medium"
          fabPosition={32}
          thumbGridSpacing={2}
          thumbRowLenght={5}
        />
        <Container maxWidth="xl">
          <Grid container spacing={3} className={classes.pageSection}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h2" align="center" gutterBottom>
                {this.props.siteNameFull}
              </Typography>
              <Typography
                component="h2"
                variant="h5"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                {this.props.siteNameSlogan}
              </Typography>
              <Typography align="center" gutterBottom>
                Platforma de educație și cercetare Măgurele
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={clsx(classes.pageSection, classes.secDesc)}>
            <Grid item xs={12} md={6}>
              <Typography
                component="h2"
                variant="h5"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Conferința susține schimbul de idei și de bune practici în domeniul Educației pentru Știință, promovând
                inițiativele de creare și stimulare a comunităților de învățare, a comunităților de bune practici și a
                ecosistemelor de orientare și dezvoltare profesională pentru elevi și studenți
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              Temele sunt ilustrate urmărind obiectivele generale ale conferinței:
              <ul>
                <li>împărtășirea de bune practici,</li>
                <li>
                  încurajarea dialogului între diferite categorii interesate de domeniul educației STEM și a carierelor
                  în domeniu,
                </li>
                <li>consolidarea parteneriatelor pentru dezvoltarea comunității pentru educația STEM,</li>
                <li>
                  promovarea cercetării în domeniul educației STEM pentru fundamentarea politicilor educaționale în
                  domeniu,
                </li>
              </ul>
              în cadrul unor ateliere, prezentări, mese rotunde cu dezbateri, vizite în laboratoare educaționale și de
              cercetare.
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.pageSection}>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Program
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={3} />
            </Hidden>
            <Grid item xs={12} md={6}>
              <Typography
                component="ul"
                variant="h6"
                style={{ margin: 0, paddingInlineStart: 0, listStyle: 'none', textAlign: 'center' }}>
                <Typography component="li" variant="h6" gutterBottom>
                  <strong>Vineri, 6 decembrie</strong> <br />
                  <Typography variant="body2">
                    vizite în institute <br />
                    deschidere <br />
                    keynote speakers
                  </Typography>
                </Typography>
                <Typography component="li" variant="h6" gutterBottom>
                  <strong>Sâmbătă, 7 decembrie</strong> <br />
                  <Typography variant="body2">
                    ateliere <br />
                    mese rotunde <br />
                    panel de experți
                  </Typography>
                </Typography>
                <Typography component="li" variant="h6" gutterBottom>
                  <strong>Duminică, 8 decembrie</strong> <br />
                  <Typography variant="body2">
                    ateliere <br />
                    concluzii și închidere
                  </Typography>
                </Typography>
                <Typography component="li" variant="h6" gutterBottom>
                  <Button variant="contained" color="secondary" component={NavLink} to="/ro/program">
                    Programul complet
                  </Button>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={clsx(classes.pageSection, classes.primDesc)}>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Informații despre sprijinul financiar
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={3} />
            </Hidden>
            <Grid item xs={12} md={6}>
              <Typography align="center">
                Prin eforturile organizatorilor, participarea profesorilor și profesorilor consilieri școlari din mediul
                preuniversitar va fi susținută integral sau parțial în conformitate cu condițiile menționate în
                secțiunea{' '}
                <Button color="secondary" component={NavLink} to="/ro/participare">
                  Participare
                </Button>
                . Cuantumul taxei de participare este de <strong>950 de lei</strong> pentru trei zile.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.pageSection}>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Organizatori
              </Typography>
            </Grid>
            {/* <Hidden smDown>
              <Grid item xs={false} md={3} />
            </Hidden>
            <Grid item xs={12} md={6}>
              <ul style={{ margin: 0, listStyle: 'none', textAlign: 'center' }}>
                <li>
                  <strong style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>UB</strong>{' '}
                  Universitatea din București
                </li>
                <li>
                  <strong style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>IFIN-HH</strong>{' '}
                  Institutul Naţional pentru Fizică şi Inginerie Nucleară - Horia Hulubei
                </li>
                <li>
                  <strong style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>INCDFP</strong>{' '}
                  Institutul Naţional de Cercetare-Dezvoltare pentru Fizica Pământului
                </li>
                <li>
                  <strong style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>UBB</strong>{' '}
                  Universitatea Babeș-Bolyai
                </li>
                <li>
                  <strong style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>USV</strong>{' '}
                  Universitatea „Ștefan cel Mare” Suceava
                </li>
                <li>
                  <strong style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>UAIC</strong>{' '}
                  Universitatea „Alexandru Ioan Cuza” din Iași
                </li>
                <li>
                  <strong style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>UMC</strong>{' '}
                  Universitatea Maritimă din Constanța
                </li>
              </ul>
            </Grid> */}
            <Grid item xs={12} lg={6}>
              <img
                src="https://cnces.educatiepentrustiinta.ro/images/CNCES-2019-Organizatori-01.png"
                alt="CNCES 2019 - Organizatori"
                style={{ maxWidth: '100%' }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <img
                src="https://cnces.educatiepentrustiinta.ro/images/CNCES-2019-Organizatori-02.png"
                alt="CNCES 2019 - Organizatori"
                style={{ maxWidth: '100%' }}
              />
            </Grid>
            <br />
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Parteneri Media
              </Typography>
            </Grid>
            <Hidden lgDown>
              <Grid item xs={false} lg={3} />
            </Hidden>
            <Grid item xs={12} lg={6}>
              <img
                src="https://cnces.educatiepentrustiinta.ro/images/CNCES-2019-Parteneri-media.png"
                alt="CNCES 2019 - Organizatori"
                style={{ maxWidth: '100%' }}
              />
            </Grid>
            <br />
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Parteneri
              </Typography>
            </Grid>
            <Hidden lgDown>
              <Grid item xs={false} lg={3} />
            </Hidden>
            <Grid item xs={12} lg={6}>
              <img
                src="https://cnces.educatiepentrustiinta.ro/images/CNCES-2019-Parteneri.png"
                alt="CNCES 2019 - Organizatori"
                style={{ maxWidth: '100%' }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className={clsx(classes.pageSection, classes.secDesc)}>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Despre noi
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={2} />
            </Hidden>
            <Grid item xs={12} md={8}>
              <Typography align="center" gutterBottom>
                <strong>Comunitatea Educație pentru Știință</strong> reprezintă un grup de inițiativă care are
                obiectivul de a crea o un mediu profesional integrator în domeniul educației pentru științe, prin
                dezvoltarea unei platforme de dialog și acțiune.
              </Typography>
              <Typography align="center">
                <Button color="primary" size="large" variant="outlined" component={NavLink} to="/ro/despre-noi">
                  Mai multe informații
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Home)
