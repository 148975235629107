import React from 'react'
import ReactDOM from 'react-dom'

import config from './config'
import './styles.css'
import App from './app'

export default function Index() {
  // React.useEffect(() => {
  //   document.title = config.siteName
  // }, [])
  return <App config={config} />
}

const rootElement = document.getElementById('root')
ReactDOM.render(<Index />, rootElement)
