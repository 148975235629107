import React from 'react'

// import { useTheme } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import RouteScrollTop from '../comps/assets/RouteScrollTop'

export default function Page(props) {
  // const theme = useTheme()

  return (
    <Container maxWidth="xl">
      <RouteScrollTop />
      <Typography component="h1" variant="h2" align="center">
        {props.pageName}
      </Typography>
      <p>text politica</p>
    </Container>
  )
}
