import Home from '../layouts/Home'
import Page from '../layouts/Page'
import DespreNoi from '../layouts/DespreNoi'
import Politica from '../layouts/Politica'
import Participare from '../layouts/Participare'
import Register from '../layouts/Register'
import Program from '../layouts/Program'

const componentList = {
  // LAYOUT
  Home: Home,
  Page: Page,
  // CONTENT
  DespreNoi: DespreNoi,
  Participare: Participare,
  Register: Register,
  Program: Program,
  // site info
  Politica: Politica,
}

export default componentList
