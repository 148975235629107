import React from 'react'
import { NavLink } from 'react-router-dom'

import clsx from 'clsx'
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

import Icon from '@mdi/react'
import { mdiMapMarker, mdiClock } from '@mdi/js'

import ActivitiesCard from '../comps/ActivitiesCard'
import RouteScrollTop from '../comps/assets/RouteScrollTop'

const styles = theme => ({
  headerContainer: {
    position: 'relative',
    top: theme.spacing(-12),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(-7),
    },
    '& img': {
      width: '100%',
      minHeight: 384,
      maxHeight: 512,
      objectFit: 'cover',
    },
  },
  pageSection: {
    padding: theme.spacing(10, 4),
    '&:first-of-type': {
      paddingTop: theme.spacing(0),
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(-12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 2),
      '&:first-of-type': {
        paddingTop: theme.spacing(3),
      },
      '&:last-of-type': {
        marginBottom: theme.spacing(-8),
      },
    },
  },
  primDesc: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  secDesc: {
    backgroundColor: theme.palette.secondary.main,
  },
  delimiter: {
    [theme.breakpoints.up('sm')]: { borderTop: '1px solid #ccc' },
  },

  linkIcon: { fill: theme.palette.primary.main },
  linkText: { textTransform: 'none' },

  legenda: { margin: theme.spacing(0, -1.5, 4) },
  dayContainer: { margin: theme.spacing(4, 0) },
  day: {
    backgroundColor: theme.palette.secondary.main,
  },
  time: { [theme.breakpoints.down('xs')]: { marginTop: theme.spacing(4) } },
  timeIcon: {
    // fill: theme.palette.secondary.main,
    // fill: 'white',
    [theme.breakpoints.down('xs')]: { fill: 'white' },
  },
  entryTitle: {
    fontSize: '1.1rem',
  },
  sesiuni: {
    padding: theme.spacing(2),
  },
  atelierPrim: {
    backgroundColor: 'rgb(182, 215, 168)',
    // color: 'rgb(182, 215, 168)'
  },
  atelierSTEM: {
    backgroundColor: 'rgb(241,241,241)',
    // color: 'rgb(243,243,243)'
  },
  atelierCons: {
    backgroundColor: 'rgb(159, 197, 232)',
    // color: 'rgb(159, 197, 232)'
  },
  prezentari: {
    backgroundColor: 'rgb(230, 184, 175)',
    // color: 'rgb(230, 184, 175)'
  },
})

export default withWidth()(
  withStyles(styles)(
    class Page extends React.Component {
      render() {
        const { classes, program } = this.props
        let gridSpacing = isWidthDown('xs', this.props.width) ? 2 : 4
        let timeColor = isWidthDown('xs', this.props.width) ? 'primary' : 'default'
        return (
          <React.Fragment>
            <div className={classes.headerContainer}>
              <img src="https://cnces.educatiepentrustiinta.ro/images/page-headers/page-header-03.jpg" alt="" />
            </div>

            <Container maxWidth="xl">
              <RouteScrollTop />
              <Grid container spacing={3} align="center" className={classes.pageSection}>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h2" align="center">
                    {this.props.pageName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Container maxWidth="md">
                    <Grid container spacing={3} className={classes.legenda}>
                      <Grid item xs={12} align="center">
                        <Typography component="h2" variant="h5">
                          Legendă
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={1} align="center" className={classes.delimiter}>
                        <Paper className={classes.atelierPrim} elevation={4}>
                          &nbsp;
                        </Paper>
                      </Grid>
                      <Grid item xs={10} sm={5} align="left" className={classes.delimiter}>
                        Atelier Primar
                      </Grid>
                      <Grid item xs={2} sm={1} align="center" className={classes.delimiter}>
                        <Paper className={classes.atelierSTEM} elevation={4}>
                          &nbsp;
                        </Paper>
                      </Grid>
                      <Grid item xs={10} sm={5} align="left" className={classes.delimiter}>
                        Atelier profesori STEM
                      </Grid>
                      <Grid item xs={2} sm={1} align="center" className={classes.delimiter}>
                        <Paper className={classes.atelierCons} elevation={4}>
                          &nbsp;
                        </Paper>
                      </Grid>
                      <Grid item xs={10} sm={5} align="left" className={classes.delimiter}>
                        Atelier Consilieri
                      </Grid>
                      <Grid item xs={2} sm={1} align="center" className={classes.delimiter}>
                        <Paper className={classes.prezentari} elevation={4}>
                          &nbsp;
                        </Paper>
                      </Grid>
                      <Grid item xs={10} sm={5} align="left" className={classes.delimiter}>
                        Prezentări <br />
                        (accesibile tuturor participanților)
                      </Grid>
                    </Grid>
                  </Container>

                  <Container maxWidth="md" className={classes.dayContainer}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={12} align="center" className={clsx(classes.day, classes.delimiter)}>
                        <Typography component="h2" variant="h5">
                          Vineri, 6 decembrie
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="08:30 - 09:00"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        <Typography component="h3" variant="h6" className={classes.entryTitle}>
                          Înregistrarea participanților
                        </Typography>
                        <Button
                          variant="text"
                          color="primary"
                          startIcon={<Icon path={mdiMapMarker} size={1} className={classes.linkIcon} />}
                          className={classes.linkText}
                          href="https://goo.gl/maps/6N1ettyKbkotvN8L8"
                          target="_blank">
                          Holul Facultății de Fizică, Str. Atomiștilor 405
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="09:00 - 12:00"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center">
                        <Grid container spacing={gridSpacing}>
                          <Grid item xs={12} align="center" className={classes.delimiter}>
                            Vizită institute și laboratoare de cercetare: <br />
                            <Button
                              variant="text"
                              color="primary"
                              startIcon={<Icon path={mdiMapMarker} size={1} className={classes.linkIcon} />}
                              className={classes.linkText}
                              href="https://goo.gl/maps/LPzBE4cHy17o5sW26"
                              target="_blank">
                              IFIN-HH
                            </Button>
                            <Button
                              variant="text"
                              color="primary"
                              startIcon={<Icon path={mdiMapMarker} size={1} className={classes.linkIcon} />}
                              className={classes.linkText}
                              href="https://goo.gl/maps/UDknTUiDtgHPudVK7"
                              target="_blank">
                              ELI-NP
                            </Button>
                            <Button
                              variant="text"
                              color="primary"
                              startIcon={<Icon path={mdiMapMarker} size={1} className={classes.linkIcon} />}
                              className={classes.linkText}
                              href="https://goo.gl/maps/BeiP7bUHyty7YdSeA"
                              target="_blank">
                              INCDFP
                            </Button>
                            <Button
                              variant="text"
                              color="primary"
                              startIcon={<Icon path={mdiMapMarker} size={1} className={classes.linkIcon} />}
                              className={classes.linkText}
                              href="https://goo.gl/maps/9G7Vj4E9uMW97xxo8"
                              target="_blank">
                              INFLPR
                            </Button>
                            <Button
                              variant="text"
                              color="primary"
                              startIcon={<Icon path={mdiMapMarker} size={1} className={classes.linkIcon} />}
                              className={classes.linkText}
                              href="https://goo.gl/maps/HXfvZWBXKwNu75Dr6"
                              target="_blank">
                              CETAL
                            </Button>
                            <Button
                              variant="text"
                              color="primary"
                              startIcon={<Icon path={mdiMapMarker} size={1} className={classes.linkIcon} />}
                              className={classes.linkText}
                              href="https://goo.gl/maps/E5BuTuPYZG1NJbDe9"
                              target="_blank">
                              INCDFM
                            </Button>
                            , etc.
                          </Grid>
                          <Grid item xs={12} align="center" className={classes.delimiter}>
                            Întâlnirea societăților profesionale de ştiinţe partenere CNCES
                            <br />
                            (pe bază de invitație)
                            <Typography variant="body2" color="textSecondary" component="p">
                              Moderator: Roxana Zus, UB-FF
                            </Typography>
                            <br />
                            <Button
                              variant="text"
                              color="primary"
                              startIcon={<Icon path={mdiMapMarker} size={1} className={classes.linkIcon} />}
                              className={classes.linkText}
                              href="https://goo.gl/maps/yf3foFqtMJDzkZzbA"
                              target="_blank">
                              Biblioteca IFIN-HH
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="12:00 - 13:00"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        Pauză de masă
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="13:00 - 14:00"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        <Typography component="h3" variant="h6" className={classes.entryTitle}>
                          Deschidere eveniment
                        </Typography>
                        <Button
                          variant="text"
                          color="primary"
                          startIcon={<Icon path={mdiMapMarker} size={1} className={classes.linkIcon} />}
                          className={classes.linkText}
                          href="https://goo.gl/maps/6N1ettyKbkotvN8L8"
                          target="_blank">
                          Aula Facultății de Fizică
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="14:00 - 14:30"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        Pauză de cafea
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="14:30 - 17:00"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        <Typography component="h3" variant="h6" className={classes.entryTitle}>
                          Keynote speakers
                        </Typography>
                        <Button
                          variant="text"
                          color="primary"
                          startIcon={<Icon path={mdiMapMarker} size={1} className={classes.linkIcon} />}
                          className={classes.linkText}
                          href="https://goo.gl/maps/L7Ji6GuG9KuL9Rb66"
                          target="_blank">
                          Sala de Festivități IFIN-HH
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="18:00"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        Cină festivă
                      </Grid>
                    </Grid>
                  </Container>

                  <Container maxWidth="md" className={classes.dayContainer}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={12} align="center" className={clsx(classes.day, classes.delimiter)}>
                        <Typography component="h2" variant="h5">
                          Sâmbătă, 7 decembrie
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="09:30 - 11:00"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        <Grid container spacing={gridSpacing}>
                          <Grid item xs={12}>
                            <Typography component="h3" variant="h6" className={classes.entryTitle}>
                              Sesiune ateliere interactive în paralel (I)
                            </Typography>
                          </Grid>
                          {program.sambata.h0930.map((act, i) => {
                            return (
                              <Grid key={i} item {...act.gridProps}>
                                <ActivitiesCard {...act} />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="11:00 - 11:30"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        Pauză de cafea
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="11:30 - 13:30"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        <Grid container spacing={gridSpacing}>
                          {program.sambata.h1130.map((act, i) => {
                            return (
                              <Grid key={i} item {...act.gridProps}>
                                <ActivitiesCard {...act} />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="13:30 - 14:30"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        Pauză de masă
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="14:30 - 16:00"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        <Grid container spacing={gridSpacing}>
                          <Grid item xs={12}>
                            <Typography component="h3" variant="h6" className={classes.entryTitle}>
                              Mese rotunde în paralel
                            </Typography>
                          </Grid>
                          {program.sambata.h1430.map((act, i) => {
                            return (
                              <Grid key={i} item {...act.gridProps}>
                                <ActivitiesCard {...act} />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="16:00 - 16:30"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        Pauză cafea
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="16:30 - 18:00"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        <Grid container spacing={gridSpacing}>
                          <Grid item xs={12}>
                            <Typography component="h3" variant="h6" className={classes.entryTitle}>
                              Sesiune ateliere interactive în paralel (II)
                            </Typography>
                          </Grid>
                          {program.sambata.h1630.map((act, i) => {
                            return (
                              <Grid key={i} item {...act.gridProps}>
                                <ActivitiesCard {...act} />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>

                  <Container maxWidth="md" className={classes.dayContainer}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={12} align="center" className={clsx(classes.day, classes.delimiter)}>
                        <Typography component="h2" variant="h5">
                          Duminică, 8 decembrie
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="09:30 - 11:00"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        <Grid container spacing={gridSpacing}>
                          <Grid item xs={12}>
                            <Typography component="h3" variant="h6" className={classes.entryTitle}>
                              Sesiune ateliere interactive în paralel (III)
                            </Typography>
                          </Grid>
                          {program.duminica.h0930.map((act, i) => {
                            return (
                              <Grid key={i} item {...act.gridProps}>
                                <ActivitiesCard {...act} />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="11:00 - 11:30"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        Pauză de cafea
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="11:30 - 13:30"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        <Grid container spacing={gridSpacing}>
                          <Grid item xs={12}>
                            <Typography component="h3" variant="h6" className={classes.entryTitle}>
                              Sesiune ateliere interactive în paralel (IV)
                            </Typography>
                          </Grid>
                          {program.duminica.h1130.map((act, i) => {
                            return (
                              <Grid key={i} item {...act.gridProps}>
                                <ActivitiesCard {...act} />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.delimiter}>
                        <Chip
                          size="small"
                          color={timeColor}
                          className={classes.time}
                          icon={<Icon path={mdiClock} size={0.78} className={classes.timeIcon} />}
                          label="13:00 - 14:00"
                        />
                      </Grid>
                      <Grid item xs={12} sm={10} align="center" className={classes.delimiter}>
                        <Typography component="h3" variant="h6" className={classes.entryTitle}>
                          Sesiune de închidere
                        </Typography>
                        <Button
                          variant="text"
                          color="primary"
                          startIcon={<Icon path={mdiMapMarker} size={1} className={classes.linkIcon} />}
                          className={classes.linkText}
                          href="https://goo.gl/maps/L7Ji6GuG9KuL9Rb66"
                          target="_blank">
                          Sala de Festivități IFIN-HH
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
              </Grid>
            </Container>
          </React.Fragment>
        )
      }
    },
  ),
)
