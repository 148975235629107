import React from 'react'

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'

// import componentList from '../../core/ComponentIterator'

// const styles = theme => ({})

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    margin: 0,
    padding: theme.spacing(2, 3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
    marginTop: 0,
    textTransform: 'uppercase',
  },
  dialogButton: {
    position: 'absolute',
    top: 50,
    left: 50,
  },
  customDialog: {
    '& h2:first-of-type': {
      marginTop: 0,
    },
  },
}))

const DialogTitle = props => {
  const classes = useStyles()
  const { children, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2, 3),
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1, 2),
  },
}))(MuiDialogActions)

const Transition = React.forwardRef(function Transition(props, ref) {
  // console.log(props)
  return <Slide direction="up" ref={ref} {...props} />
})

function createMarkup(content) {
  return { __html: content }
}

export default withWidth()(function FullScreenDialog(props) {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(true)
  }

  const handleClose = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(false)
  }

  const dialogData = props.data
  // const ComponentIterator = componentList[dialogData.component]
  // console.log(props.data)

  return (
    <div
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
      }}
      onScroll={e => {
        e.stopPropagation()
        e.preventDefault()
      }}
      style={{
        flexGrow: 1,
        display: 'flex',
        // flexDirection: 'column',
        // justifyContent: `${props.justifyContent}`,
        justifyContent: `flex-end`,
      }}>
      <Button
        fullWidth={false}
        variant={dialogData.buttonVariant ? dialogData.buttonVariant : 'text'}
        color={dialogData.buttonColor ? dialogData.buttonColor : 'default'}
        size={dialogData.buttonSize ? dialogData.buttonSize : 'medium'}
        onClick={handleClickOpen}
        style={{
          // flex: '0 1 auto',
          // alignSelf: `${dialogData.alignItems}`,
          marginRight: 8,
        }}>
        {dialogData.buttonText}
      </Button>
      <Dialog
        aria-labelledby="scroll-dialog-title"
        fullScreen={
          dialogData.props.fullScreen ? dialogData.props.fullScreen : isWidthDown('xs', props.width) ? true : false
        }
        maxWidth={dialogData.props.maxWidth ? dialogData.props.maxWidth : 'md'}
        fullWidth={dialogData.props.fullWidth ? dialogData.props.fullWidth : true}
        scroll={dialogData.props.scroll ? dialogData.props.scroll : 'paper'}
        hideBackdrop
        elevation={24}
        open={open}
        onClose={handleClose}
        // TransitionComponent={React.forwardRef(function Transition(props, ref) {
        //   return <Slide direction={dialogData.transition} ref={ref} {...props} />
        // })}
        TransitionComponent={Transition}
        className={classes.customDialog}>
        {dialogData.props.fullScreen && isWidthUp('sm', props.width) ? (
          <React.Fragment>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography
                  variant="h6"
                  id="scroll-dialog-title"
                  className={classes.title}
                  style={{ marginLeft: theme.spacing(2) }}>
                  {/* {dialogData.content.title} */}
                  Detalii Atelier
                </Typography>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            {
              <Container maxWidth="md" style={{ marginTop: 32 }}>
                <Grid container spacing={3}>
                  {props.children && (
                    <Grid item xs={12}>
                      {props.children}
                    </Grid>
                  )}
                  {dialogData.content && (
                    <Grid item xs={12} dangerouslySetInnerHTML={createMarkup(dialogData.content)} />
                  )}
                </Grid>
              </Container>
            }
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DialogTitle id="scroll-dialog-title" onClose={handleClose}>
              {/* {dialogData.content.title} */}
              Detalii Atelier
            </DialogTitle>
            {dialogData.content && (
              <DialogContent dividers>
                <Typography component="h1" variant={isWidthUp('sm', props.width) ? 'h3' : 'h4'} gutterBottom>
                  {dialogData.content.title}
                </Typography>
                <br />
                {dialogData.content.trainers && (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                        Formatori:
                      </Typography>
                      <Typography
                        variant="body1"
                        gutterBottom
                        dangerouslySetInnerHTML={createMarkup(dialogData.content.trainers)}
                      />
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.moderators && (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                        Moderatori:
                      </Typography>
                      <Typography
                        variant="body1"
                        gutterBottom
                        dangerouslySetInnerHTML={createMarkup(dialogData.content.moderators)}
                      />
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.duration && (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                        Timp alocat: {dialogData.content.duration}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.goals && (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                        Scopul atelierului:
                      </Typography>
                      <Typography gutterBottom>{dialogData.content.goals}</Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.guests && (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                        Invitați:
                      </Typography>
                      <Typography component="ul">
                        {dialogData.content.guests.map((guest, i) => {
                          return <li key={i} dangerouslySetInnerHTML={createMarkup(guest)} />
                        })}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.targetAudience && (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                        Publicul țintă:
                      </Typography>
                      <Typography gutterBottom>{dialogData.content.targetAudience}</Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.invited && (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                        Prezenți în sală:
                      </Typography>
                      <Typography gutterBottom>{dialogData.content.invited}</Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.objectives && (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                        Obiective:
                      </Typography>
                      <Typography component="ul">
                        {dialogData.content.objectives.map((objective, i) => {
                          return <li key={i} dangerouslySetInnerHTML={createMarkup(objective)} />
                        })}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.themes && (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                        Teme abordate:
                      </Typography>
                      <Typography component="ol">
                        {dialogData.content.themes.map((theme, i) => {
                          return <li key={i} dangerouslySetInnerHTML={createMarkup(theme)} />
                        })}
                      </Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.image && (
                  <React.Fragment>
                    <div>
                      <img
                        src={`https://cnces.educatiepentrustiinta.ro/images/ateliere/${dialogData.content.image}`}
                        alt={dialogData.content.title}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.info && (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                        Descriere:
                      </Typography>
                      <Typography gutterBottom dangerouslySetInnerHTML={createMarkup(dialogData.content.info)} />
                    </div>
                    <br />
                  </React.Fragment>
                )}
                {dialogData.content.resources && (
                  <React.Fragment>
                    <div>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }} gutterBottom>
                        Resurse necesare:
                      </Typography>
                      <Typography gutterBottom>{dialogData.content.resources}</Typography>
                    </div>
                    <br />
                  </React.Fragment>
                )}
              </DialogContent>
            )}
            {props.children && <DialogContent dividers>{props.children}</DialogContent>}
            {dialogData.hasActionButton && (
              <DialogActions>
                {dialogData.buttonType ? (
                  <Button autoFocus color="inherit" type={dialogData.buttonType}>
                    {dialogData.buttonActionText}
                  </Button>
                ) : (
                  <Button autoFocus color="inherit" onClick={handleClose}>
                    {dialogData.buttonActionText}
                  </Button>
                )}
              </DialogActions>
            )}
          </React.Fragment>
        )}
      </Dialog>
    </div>
  )
})
