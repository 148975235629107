/* eslint-disable import/first */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import List from '@material-ui/core/List'
// import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
// import ListItemIcon from "@material-ui/core/ListItemIcon";

import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'

import SiteLogo from './assets/SiteLogo'

const styles = theme => ({
  root: {
    flexGrow: 1,
    // backgroundColor: "transparent",
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(2),
      right: theme.spacing(2),
      left: theme.spacing(2),
      width: `calc(100vw - ${theme.spacing(6)}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.common.white,
      fill: theme.palette.primary.main,
    },
  },
  menuButtonIcon: {
    fill: theme.palette.common.white,
  },
  menuButtonHome: {
    textTransform: 'none',
  },
  title: {
    // flexGrow: 1,
    padding: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      // backgroundColor: 'transparent',
  },
  },
  siteLogo: {
    width: theme.spacing(4),
    marginTop: theme.spacing(-0.5),
    fill: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      // marginRight: theme.spacing(1),
    },
  },
  SwipeableDrawer: {
    '&>.MuiDrawer-paper': {
      [theme.breakpoints.down('sm')]: {
        width: '224px',
        right: '0 !important',
        left: 'auto !important',
      },
      [theme.breakpoints.only('xs')]: {},
    },
  },
  menuButtonList: {
    color: 'white',
    fontWeight: 400,
  },
  menuButtonListText:{
    fontWeight: 400,
  },
  selectedNavLink: {
    color: 'orange',
    fontWeight: 400,
  },
  sideList: {
    width: 250,
  },
  fullList: {
    width: 'auto',
    textAlign: 'center',
    '& .MuiListItem-root': {
      textAlign: 'center',
    },
  },
})

export default withWidth()(
  withStyles(styles)(
    class ButtonAppBar extends React.Component {
      constructor(props) {
        super(props)
        this.state = {
          [this.props.position]: false,
        }
      }

      render() {
        const {
          classes,
          position,
          siteStructure,
          siteName,
          siteLogo,
          siteLogoType,
          showLogo,
          showSiteName,
          siteDefaultLanguage,
        } = this.props
        const siteLanguage = siteDefaultLanguage

        const toggleDrawer = (side, open) => event => {
          if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
          }

          this.setState({ ...this.state, [side]: open })
        }

        // console.log(siteStructure)
        const drawerList = side => (
          <div
            className={
              ['left', 'right'].includes(this.props.position)
                ? this.props.classes.sideList
                : this.props.classes.fullList
            }
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}>
            <List
              aria-labelledby="nested-list-subheader"
              // subheader={
              //   <ListSubheader component="div" id="nested-list-subheader">
              //     {this.state[this.props.position]}
              //   </ListSubheader>
              // }
            >
              {siteStructure.map((item, index) =>
                item.nav ? (
                  <ListItem
                    key={index}
                    to={'/' + siteLanguage + '/' + item.path}
                    exact
                    component={NavLink}
                    className={classes.menuButtonList}
                    activeClassName={classes.selectedNavLink}>
                    <ListItemText primary={item.name} classes={{primary: classes.menuButtonListText}} />
                  </ListItem>
                ) : null,
              )}
            </List>
          </div>
        )

        if (isWidthUp('md', this.props.width)) {
          return (
            <AppBar position="fixed" className={classes.root}>
              <Toolbar>
                {showLogo && (
                  <Fab color="primary" className={classes.title} component={NavLink} to="/" style={{ marginRight: 16 }}>
                    <SiteLogo siteLogo={siteLogo} siteLogoType={siteLogoType} siteName={siteName} />
                  </Fab>
                )}
                {JSON.parse(showSiteName) && <Typography>{siteName}</Typography>}
                <div className={classes.grow} />
                {siteStructure.map((item, index) =>
                  item.nav ? (
                    index === 0 ? (
                      <Button
                        to={'/' + siteLanguage + '/' + item.path}
                        component={NavLink}
                        className={classes.menuButton}
                        key={index}>
                        <HomeIcon />
                      </Button>
                    ) : (
                      <Button
                        to={'/' + siteLanguage + '/' + item.path}
                        component={NavLink}
                        className={classes.menuButton}
                        // activeClassName={classes.selectedNavLink}
                        key={index}>
                        {item.name}
                      </Button>
                    )
                  ) : null,
                )}
              </Toolbar>
            </AppBar>
          )
        } else {
          return (
            <React.Fragment>
              <AppBar position="fixed" className={classes.root}>
                <Toolbar>
                  {showLogo && (
                    <Fab
                      color="primary"
                      className={classes.title}
                      component={NavLink}
                      to="/"
                      style={{ marginRight: 8 }}>
                      <SiteLogo siteLogo={siteLogo} siteLogoType={siteLogoType} siteName={siteName} />
                    </Fab>
                  )}
                  {isWidthUp('sm', this.props.width) && JSON.parse(showSiteName) && <Typography>{siteName}</Typography>}
                  <div style={{ flexGrow: 1 }} />
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    aria-label="Menu"
                    onClick={toggleDrawer(position, true)}
                    style={{ margin: 0 }}>
                    <MenuIcon className={classes.menuButtonIcon} />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <SwipeableDrawer
                anchor={position}
                className={classes.SwipeableDrawer}
                open={this.state[position]}
                onClose={toggleDrawer(position, false)}
                onOpen={toggleDrawer(position, true)}>
                {drawerList(position)}
              </SwipeableDrawer>
            </React.Fragment>
          )
        }
      }
    },
  ),
)
