import React from 'react'
import { NavLink } from 'react-router-dom'

import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import RouteScrollTop from '../comps/assets/RouteScrollTop'
import FAQ from './FAQ'

const styles = theme => ({
  headerContainer: {
    position: 'relative',
    top: theme.spacing(-12),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(-7),
    },
    '& img': {
      width: '100%',
      minHeight: 384,
      maxHeight: 512,
      objectFit: 'cover',
    },
  },
  pageSection: {
    padding: theme.spacing(10, 4),
    '&:first-of-type': {
      paddingTop: theme.spacing(0),
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(-12),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 2),
      '&:first-of-type': {
        paddingTop: theme.spacing(3),
      },
      '&:last-of-type': {
        marginBottom: theme.spacing(-8),
      },
    },
  },
  primDesc: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  secDesc: {
    backgroundColor: theme.palette.secondary.main,
  },
})

class Page extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <div className={classes.headerContainer}>
          <img src="https://cnces.educatiepentrustiinta.ro/images/page-headers/page-header-01.jpg" alt="" />
        </div>

        <Container maxWidth="xl">
          <RouteScrollTop />
          <Grid container spacing={3} className={classes.pageSection}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h2" align="center">
                {this.props.pageName}
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={2} />
            </Hidden>
            <Grid item xs={12} md={8}>
              <p>
                Înscrierea se desfăşoară în perioada 8 – 19 noiembrie prin completarea formularului de înregistrare.
                Participanţii pot să îşi aleagă zilele în care doresc să participe la activităţi ( 1 – 3 zile) şi tipul
                de activităţi la care doresc să participe.
              </p>
              <p>
                Sprijinul financiar se acordă conform condiţiilor de eligibilitate descrise în pagina{' '}
                <Button variant="text" color="primary" component={NavLink} to="/ro/participare">
                  Participare
                </Button>
                .
              </p>
              <p>
                După completarea formularului veţi primi un mesaj automat de confirmare a înregistrării cererii și un
                link prin care veţi avea posibilitatea să faceţi modificări ale datelor înregistrate, dacă este cazul.
              </p>
              <p>
                Selecţia se va face în limita locurilor disponibile luând în considerare următoarele criterii:
                apartenenţa la una dintre categoriile grupului ţintă, opţiunile privind zilele de participare, opţiunile
                exprimate pentru participarea la activităţi, dacă este aplicant pentru o bursă de participare,
                asigurarea unei distribuţii echilibrate regional, în acord cu criteriile specifice ale organizatorilor
                conferinţei.
              </p>
              <p style={{ textAlign: 'center' }}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdaN-0wsC_89fRHsShVkN-D_eRAK1GFVznlAMeXLkG82nyUCw/viewform"
                  target="_blank">
                  Formular de înscriere
                </Button>
              </p>
              <p>
                Organizatorii susţin financiar participarea a aproximativ 100 de participanţi, majoritatea cadre
                didactice din învăţământul preuniversitar. Pentru alte categorii (exceptând cadrele didactice din
                învăţământul preuniversitar) există posibilitatea participării prin plata unei taxe de 950 Ron pentru
                întreaga perioadă a conferinţei sau 330 Ron pentru o zi.
              </p>
            </Grid>
            <Grid item xs={12} />
          </Grid>
          <Grid container spacing={3} className={clsx(classes.pageSection, classes.secDesc)}>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Date importante
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={3} />
            </Hidden>
            <Grid item xs={12} md={6}>
              <Typography
                component="ul"
                variant="h6"
                style={{ margin: 0, paddingInlineStart: 0, listStyle: 'none', textAlign: 'center' }}>
                <Typography component="li" variant="h6" gutterBottom>
                  <strong>8 noiembrie - 19 noiembrie</strong> <br />
                  <Typography variant="body2">înregistrarea cererilor de participare</Typography>
                </Typography>
                <Typography component="li" variant="h6" gutterBottom>
                  <strong>21 noiembrie</strong> <br />
                  <Typography variant="body2">anunţarea participanților selectați pentru burse</Typography>
                </Typography>
                <Typography component="li" variant="h6" gutterBottom>
                  <strong>25 noiembrie</strong> <br />
                  <Typography variant="body2">data limită pentru confirmarea participării</Typography>
                </Typography>
                <Typography component="li" variant="h6" gutterBottom>
                  <strong>30 noiembrie</strong> <br />
                  <Typography variant="body2">selectarea opţiunilor pentru participarea la ateliere</Typography>
                </Typography>
                <Typography component="li" variant="h6" gutterBottom>
                  <strong>3 decembrie</strong> <br />
                  <Typography variant="body2">finalizarea listelor de participanţi</Typography>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} className={clsx(classes.pageSection)}>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                align="center"
                gutterBottom
                style={{ fontFamily: 'Bree Serif, Roboto, Helvetica, Arial, sans-serif' }}>
                Întrebări și răspunsuri
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item xs={false} md={3} />
            </Hidden>
            <Grid item xs={12} md={6}>
              <FAQ />
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Page)
