import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

import Grid from '@material-ui/core/Grid'

import PaginationDot from './PaginationDot'

const styles = theme => ({
  root: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    right: 32,
    [theme.breakpoints.up('md')]: {
      bottom: 24,
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: 96,
      left: 32,
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      bottom: 64,
    },
  },
})

class Pagination extends React.Component {
  handleClick = (event, index) => {
    this.props.onChangeIndex(index)
    if (this.props.autoplay) this.props.handleAutoPlay() // eslint-disable-line no-use-before-define
  }

  returnDots() {
    const { index, images, dotSize } = this.props

    const rowLenght = !this.props.rowLenght ? 4 : this.props.rowLenght
    const imagesCounter = images.length > rowLenght ? rowLenght : images.length

    return (
      <React.Fragment>
        {images.length > 1 &&
          images.map((image, i) => {
            return (
              <Grid key={i} item>
                <PaginationDot
                  key={i}
                  image={image.src}
                  dotSize={dotSize}
                  index={i}
                  active={i === this.props.index}
                  onClick={this.handleClick}
                />
              </Grid>
            )
          })}
      </React.Fragment>
    )
  }

  render() {
    const { classes, index, images, spacing, dotSize } = this.props

    return (
      <Grid
        container
        spacing={spacing}
        className={classes.root}
        style={{
          width: isWidthUp('md', this.props.width)
            ? images.length * dotSize + images.length * spacing * 8
            : 'calc(100vw - 64px)',
        }}>
        {this.returnDots()}
      </Grid>
    )
  }
}

Pagination.propTypes = {
  // dots: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.func.isRequired,
}

export default withWidth()(withStyles(styles)(Pagination))
