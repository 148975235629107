import React from 'react'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  progress: {
    color: 'rgba(255,255,255,.5)',
  },
}))

export default function CircularStatic(props) {
  const classes = useStyles()

  return (
    <div style={{ textAlign: 'center' }}>
      <CircularProgress className={clsx(props.position, classes.progress)} color="secondary" size={48} />
    </div>
  )
}
